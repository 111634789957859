import { CarouselC } from "../Carousel";

export function Dispositivos() {
  return (
    <div className="container py-4">
      <div className="col-12 text-center mb-5">
        <h2 style={{ color: "#2F2C57" }} className="title-info-home mb-4">
          Conoce Nuestros Dispositivos
        </h2>
        <p
          style={{ color: "#2F2C57" }}
          className="col-9 mx-auto paragraph-info-home"
        >
          ¡Descubre nuestra gama de dispositivos móviles de primera calidad con
          opciones de pago a crédito! Obtén lo último en tecnología móvil sin
          pagar el precio completo de inmediato. Mantente conectado y
          actualizado, ¡elige hoy tu dispositivo ideal!
        </p>
      </div>
      <CarouselC />
    </div>
  );
}
