import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import logo from "../../../assets/img/logos/logo-dark.jpg";
import "./Footer.css";
import { useNavigate } from "react-router-dom";

export function Footer() {
  const navigate = useNavigate();
  return (
    <footer className="footer py-5">
      <div className="container-md text-center">
        <div className="row px-0">
          <div className="flex justify-content-center align-items-center flex-column col-lg-6 row">
            <div
              style={{ maxWidth: "25rem" }}
              className="mb-4 d-flex justify-content-center flex-column items-center"
            >
              <h2>TECNOCREDITO SHOP S.A.S</h2>
            </div>
            <div
              style={{ maxWidth: "23rem", opacity: "70%" }}
              className=" mb-4 d-flex justify-content-center flex-column text-start items-start"
            >
              <p className="mb-1">NIT: 901740821</p>
              <p>DIRECCION: Cr 73B # 147-95 Colina</p>

              <p>TEL.3012546633</p>

              <p>TECNOCREDITO SHOP S.A.S</p>
            </div>
          </div>
          <div className="row align-items-center justify-content-center col-lg-6">
            <div
              className="col col-xs-12 col-sm-12 col-md-12 col-l-12 col-xl-12"
              onClick={() => navigate(`/`)}
            >
              <a className="footer-link btn" href="#sobre-nosotros">
                Sobre nosotros
              </a>
            </div>

            <div className="col col-xs-12 col-sm-12 col-md-12 col-l-12 col-xl-12">
              <a className="footer-link btn" href="https://wa.link/kpu8i2">
                WhatsApp
              </a>
            </div>

            <div className="col col-xs-12 col-sm-12 col-md-12 col-l-12 col-xl-12">
              <a
                className="footer-link btn"
                href="https://instagram.com/tecnocreditooficial?igshid=MzRlODBiNWFlZA=="
              >
                Instagram
              </a>
            </div>
            <div className="col col-xs-12 col-sm-12 col-md-12 col-l-12 col-xl-12">
              <a className="footer-link btn" href="/login">
                <i className="fa-solid fa-user-lock"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
