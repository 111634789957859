import tecnoCredito from "../../../assets/img/home/planes_ofertas/tecno-credito.jpg";
import aviso2 from "../../../assets/img/home/planes_ofertas/aviso2.jpg";
import descuento_30 from "../../../assets/img/home/planes_ofertas/Img.jpg";
import CircleIcon from "../svg/Arrow";
import "./planes.css";
export function Planes() {
  return (
    <>
      <div className="container py-4 mx-auto row justify-content-evenly">
        <div className=" text-center mb-5">
          <h2 style={{ color: "#2F2C57" }} className="title-info-home mb-4">
            Nuestros Planes y Ofertas
          </h2>

          <p
            style={{ color: "#2F2C57", maxWidth: "63rem" }}
            className=" mx-auto paragraph-info-home"
          >
            Nuestros llamativos planes de crédito y ofertas son la puerta de
            entrada a la tecnología móvil de tus sueños. Con plazos flexibles y
            opciones de pago adaptadas a tu presupuesto, te ofrecemos una forma
            sencilla y asequible de acceder a los dispositivos más avanzados.
          </p>

          <div className="wrapper">
            <div className="one">
              <div className="">
                <img src={descuento_30} className="imgPlanes" alt="..." />
                <div className="">
                  <h2
                    style={{ color: "#2F2C57" }}
                    className="text-start px-2 pt-2"
                  >
                    PLAN 30% -OFF
                  </h2>
                  <p style={{ color: "#2F2C57" }} className="text-start px-2">
                    Conoce nuestros descuentos y contactanos
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                borderRadius: "20px",
                backgroundColor: "#FB31A4",
                color: "#2F2C57",
                maxWidth: "25rem",
              }}
              className=" mt-3 two d-flex justify-content-between align-items-center"
            >
              <div className="fw-bold fs-5">CONTACTO</div>
              <CircleIcon />
            </div>
            <div className="three">
              <div className="">
                <img src={tecnoCredito} className="imgPlanes" alt="..." />
                <div className="">
                  <h2
                    style={{ color: "#2F2C57" }}
                    className="text-start px-2 pt-2"
                  >
                    Tu empresa Favorita
                  </h2>
                  {/* <p style={{ color: "#2F2C57" }} className="text-start px-2">
                    Descripcion del plan
                  </p> */}
                </div>
              </div>
            </div>
            <div className="four">
              <div className="">
                <img src={aviso2} className="imgPlanes" alt="..." />
                <div className="">
                  <h2
                    style={{ color: "#2F2C57" }}
                    className="text-start px-2 pt-2"
                  >
                    CALENDARIO DE PAGOS GENERAL
                  </h2>
                  {/* <p style={{ color: "#2F2C57" }} className="text-start px-2">
                    Descripcion del plan
                  </p> */}
                </div>
              </div>
            </div>
          </div>
          <p
            style={{ color: "#2F2C57", maxWidth: "63rem" }}
            className="my-4 mx-auto paragraph-info-home"
          >
            Mantente conectado con estilo y comodidad, eligiendo uno de nuestros
            planes de crédito que se adaptan a ti. ¡Descubre la libertad de
            elección y la emocion de estar siempre conectado!
          </p>
        </div>
      </div>
    </>
  );
}
