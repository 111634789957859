import React from "react";
import "./MyButton.css";
import { useNavigate } from "react-router-dom";
import CircleIcon from "../svg/Arrow";

export function MyButton({ nombre, link }) {
  const navigate = useNavigate();

  return (
    <div
      className="MyButton btn rounded-pill flex flex-row px-2"
      style={{ backgroundColor: "#FB31A4", color: "#2F2C57" }}
    >
      <div
        className="fw-semibold  fs-5 px-2"
        onClick={() => navigate(`/${link ? link : ""}`)}
      >
        {nombre}
      </div>
      <CircleIcon />
    </div>
  );
}
