import React from "react";
import {
  FixedButton,
  Documentospdf,
  Section,
  Enrolamiento,
  EnrolamientoPasos,
  Acciones,
} from "../../Components/Client";

export function Documentos() {
  return (
    <div>
      <FixedButton />
      <Section classSection="bg-white">
        <Enrolamiento />
        <EnrolamientoPasos />
      </Section>
      <Section>
        <Acciones />
      </Section>
      <Section>
        <Documentospdf />
      </Section>
    </div>
  );
}
