import React from "react";
import "./Arrow.css";
const CircleIcon = () => {
  return (
    <div className="circle">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 28 28"
      >
        <circle cx="13" cy="13" r="13" fill="#2F2C57" />
        <path
          d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z"
          fill="#F4F9FC"
        />
      </svg>
    </div>
  );
};

export default CircleIcon;
