import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Carousel.css";
import axios from "axios";

export function CarouselC() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get("https://tecno-credito-back-9aa5ad482c06.herokuapp.com/devices/api/list/");
        // const data = await response.json();
        setProducts(response.data);
        // console.log(response);
      } catch (error) {
        console.log("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="carousel" id="productos">
      <div className="container mb-4">
        <div className="row">
          <div className="col-12">
            <div className="carousel-bx wow zoomIn">
              <Carousel
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
              >
                {products ? (
                  products.map((item) => (
                    <div
                      key={item.id}
                      style={{ borderRadius: "35px" }}
                      className="item bg-white  py-3 mx-3 my-2"
                    >
                      <div
                        style={{ width: "fit-content" }}
                        className=" container d-flex justify-content-center"
                      >
                        <img
                          style={{ borderRadius: "30px" }}
                          className="img-fluid"
                          src={item.image_url}
                          alt="dispositivo1"
                        />
                      </div>
                      <div className="mx-4 py-2">
                        <p
                          style={{ color: "#2F2C57" }}
                          className="fw-bold fs-5"
                        >
                          {item.name} {item.reference}
                        </p>
                        {/* <p style={{ color: "#2F2C57" }} className=" fs-6">
                          <span className="fw-semibold">Precio:</span> $
                          {item.precio}
                        </p> */}
                      </div>
                    </div>
                  ))
                ) : (
                  <div>Imagenes no disponibles</div>
                )}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
