import "./Documentospdf.css";
import pdf from "../../../assets/img/documentos/pdf2.png";
import descargas from "../../../assets/img/icons/descargas.png";
import doc1 from "../../../assets/img/documentos/1InformacionParaElCliente.pdf";
import doc2 from "../../../assets/img/documentos/2ValidacionDeCredito.pdf";
import doc3 from "../../../assets/img/documentos/3PermisoDeReportesAnteEntidadesLegales.pdf";
import doc4 from "../../../assets/img/documentos/4Contrato.pdf";

export function Documentospdf() {
  return (
    <div className="bg-white d-flex align-items-center py-5 justify-content-center">
      <div
        className="asesoresFlex d-flex justify-content-center align-items-center"
        style={{ maxWidth: "80rem" }}
      >
        <div
          style={{ width: "50%" }}
          className="my-4 secondDiv d-flex justify-content-center align-items-center"
        >
          <div
            className="mx-5 d-flex justify-content-center align-items-start flex-column"
            style={{}}
          >
            <h2 style={{ color: "#2F2C57" }} className=" fs-2 ">
              Documentos de enrolamiento
            </h2>
            <p
              className="text-start fs-5"
              style={{ color: "#2F2C57", maxWidth: "30rem" }}
            >
              Aquí encontraras todos los documentos que necesitas para registrar
              un cliente en nuestra plataforma y posteriormente enrolar el
              dispositivo para ganar tu comisión.
            </p>
          </div>
        </div>
        <div
          style={{ width: "50%" }}
          className="my-4 gap-2 flex-column firstDiv d-flex justify-content-center align-items-center"
        >
          <a
            className="scale-on-hover documentos py-3 d-flex flex-row justify-content-between align-items-center"
            download
            href={doc1}
            style={{
              color: "#2F2C57",
              width: "100%",
              backgroundColor: "white",
              borderRadius: "8px",
            }}
          >
            <div className="mx-4 d-flex justify-content-center align-items-center">
              <img style={{ width: "3rem" }} src={pdf} />
              <h2 className="m-0 mx-4 fs-4">Informacion para el cliente</h2>
            </div>
            <div className="mx-4">
              <img style={{ width: "1.5rem" }} src={descargas} />
            </div>
          </a>
          <a
            className="scale-on-hover documentos py-3 d-flex flex-row justify-content-between align-items-center"
            download
            href={doc1}
            style={{
              color: "#2F2C57",
              width: "100%",
              backgroundColor: "white",
              borderRadius: "8px",
            }}
          >
            <div className="mx-4 d-flex justify-content-center align-items-center">
              <img style={{ width: "3rem" }} src={pdf} />
              <h2 className="m-0 mx-4 fs-4">Valoración de crédito</h2>
            </div>
            <div className="mx-4">
              <img style={{ width: "1.5rem" }} src={descargas} />
            </div>
          </a>
          <a
            className="scale-on-hover documentos py-3 d-flex flex-row justify-content-between align-items-center"
            download
            href={doc1}
            style={{
              color: "#2F2C57",
              width: "100%",
              backgroundColor: "white",
              borderRadius: "8px",
            }}
          >
            <div className="mx-4 d-flex justify-content-center align-items-center">
              <img style={{ width: "3rem" }} src={pdf} />
              <h2 className="m-0 mx-4 fs-4">
                Permiso de reportes ante entidades legales
              </h2>

              <div>
                <img style={{ width: "1.5rem" }} src={descargas} />
              </div>
            </div>
          </a>

          <a
            className="scale-on-hover documentos py-3 d-flex flex-row justify-content-between align-items-center"
            download
            href={doc1}
            style={{
              color: "#2F2C57",
              width: "100%",
              backgroundColor: "white",
              borderRadius: "8px",
            }}
          >
            <div className="mx-4 d-flex justify-content-center align-items-center">
              <img style={{ width: "3rem" }} src={pdf} />
              <h2 className="m-0 mx-4 fs-4">Contrato</h2>
            </div>
            <div className="mx-4">
              <img style={{ width: "1.5rem" }} src={descargas} />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
