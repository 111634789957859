import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./Banner.css";
import { MyButton } from "../";
import image1 from "../../../assets/img/banner/phones.webp";

export function Banner() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [smallScreen, setSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth < 620) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  }, [screenWidth]);

  return (
    <section className="banner_container ">
      <div
        style={{ backgroundColor: "#2F2C57", width: "100%", height: "100%" }}
        className={`${
          smallScreen ? "flex-column" : "flex-row"
        } d-flex align-items-center justify-content-center`}
      >
        <div
          style={{
            width: smallScreen ? "100%" : "45%",
            backgroundColor: "#F4F9FC",
            height: "100%",
            marginTop: smallScreen ? "40px" : "0",
          }}
          className="px-5 d-flex flex-column align-items-center justify-content-center py-4 "
        >
          <div>
            <h2 style={{ color: "#2F2C57" }} className="mb-0 fs-3">
              Bienvenido
            </h2>
            <h1 style={{ color: "#2F2C57" }} className="mt-2 fs-2">
              TecnoCrédito Shop S.A.S
            </h1>
            <p
              style={{ maxWidth: "30rem", color: "#2F2C57" }}
              className="text-start fs-5"
            >
              Somos una empresa lider en tecnologia con los mejores planes para
              que obtengas el equipo de tus sueños{" "}
              <span className="fw-bold">a crédito.</span>
            </p>
            <MyButton className="" nombre="Conoce más" />
          </div>
        </div>
        <div
          style={{
            width: smallScreen ? "100%" : "55%",
            height: "100%",
            position: "relative",
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <img
              src={image1}
              alt="AI"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
