import Cookies from "universal-cookie";
import { Button, Icon } from "semantic-ui-react";
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
const cookies = new Cookies();

export function NavbarSeller() {
    const Logout = () => {
        cookies.remove("id", { path: "/" });
        cookies.remove("email", { path: "/" });
        cookies.remove("name", { path: "/" });
        window.location.href = "../../login";
    };
    return(
        <Navbar className="position-sticky" expand="lg" bg="dark" data-bs-theme="dark">
            <Container>
                <h2 className="mb-0">Hola, {cookies.get("name")}</h2>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <a href="../consultCredit" className="nav-link me-lg-4">
                        <Icon name="closed captioning outline" size="big"/>
                    </a>
                    <Button onClick={() => Logout()} className="ui red button">
                        Cerrar Sesion
                    </Button>
                </Navbar.Collapse>
                
            </Container>            
        </Navbar>
    )
};
