import React, { useState, useEffect } from 'react'
import { Icon, Table } from 'semantic-ui-react'
export function TableDevices(props){
    const [devices, setDevices] = useState(null);
    useEffect(() => {
        setDevices(props.devices)
    }, [props.devices]);
    return(
        <>
            <Table celled inverted>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>N.</Table.HeaderCell>
                        <Table.HeaderCell>ID device</Table.HeaderCell>
                        <Table.HeaderCell>CC</Table.HeaderCell>
                        <Table.HeaderCell>Nombre</Table.HeaderCell>
                        <Table.HeaderCell>Modelo</Table.HeaderCell>
                        <Table.HeaderCell>Activo</Table.HeaderCell>
                        <Table.HeaderCell>Editar</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    <CreateRows devices={devices}/>
                </Table.Body>
            </Table>
        </>
    )
}

function CreateRows(props) {
    if (props.devices != null) {
      return (
        <>
          {props.devices.map((device, index) => (
            <Table.Row key={index}>
              {/* Contenido de cada fila */}
              <Table.Cell>{index+1}</Table.Cell>
              <Table.Cell>{device.id}</Table.Cell>
              <Table.Cell>{device.name}</Table.Cell>
              <Table.Cell>{device.customer_details.name}</Table.Cell>
              <Table.Cell>{device.make} - {device.model}</Table.Cell>
              <Table.Cell>{device.locked ? <Icon color='red' name='close' size='large' /> : <Icon color='green' name='checkmark' size='large' />}</Table.Cell>
              <Table.Cell textAlign='center'>
                <a href={`/Aliados/RegistroDispositivo/${device.id}`}>
                    <Icon color='violet' name='edit' size='large' />
                </a>
              </Table.Cell>
            </Table.Row>
          ))}
        </>
      );
    } else {
      return null;
    }
  }