import React from "react";
import "./Brands.css";
import samsung from "../../../assets/img/logos/brands/samsung.png";
import xiaomi from "../../../assets/img/logos/brands/images.png";
import tecno from "../../../assets/img/logos/brands/tecno.png";
import infinix from "../../../assets/img/logos/brands/infinix.png";

export function Brands() {
  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        top: "-50px",
      }}
      className=" d-flex justify-content-center align-items-center"
    >
      <div
        style={{
          backgroundColor: "white",
          width: "80%",
          maxWidth: "85rem",
          borderRadius: "25px",
        }}
        className="py-3 flex-wrap white-background mx-5 gap-2 d-flex flex-row justify-content-evenly align-items-center"
      >
        <div className=" d-flex  justify-content-center align-items-center">
          <img style={{ maxHeight: "50px" }} src={samsung} />
        </div>
        <div className="py-3 d-flex  justify-content-center align-items-center">
          <img style={{ maxHeight: "40px" }} src={xiaomi} />
        </div>
        <div className="py-3 d-flex  justify-content-center align-items-center">
          <img style={{ maxHeight: "60px" }} src={tecno} />
        </div>
        <div className="py-3 d-flex  justify-content-center align-items-center">
          <img style={{ maxHeight: "16px" }} src={infinix} />
        </div>
      </div>
    </div>
  );
}
