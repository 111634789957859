import React from "react";
import "./MenuSvg.css";

const MenuSvg = ({ openNavigation }) => {
  return (
    <svg
      className={`menu-svg ${openNavigation ? "open" : ""}`}
      width="20"
      height="12"
      viewBox="0 0 20 12"
    >
      <rect
        className="menu-bar top-bar"
        width="20"
        height="2"
        rx="1"
        fill="white"
      />
      <rect
        className="menu-bar bottom-bar"
        width="20"
        height="2"
        rx="1"
        fill="white"
      />
    </svg>
  );
};

export default MenuSvg;
