import React from "react";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import {
  Banner,
  Brands,
  Planes,
  Section,
  Asesores,
  Dispositivos,
  TrabajaConNosotros,
  FixedButton,
} from "../../Components/Client";

export function Home() {
  return (
    <div>
      <Section classSection="bg-white pb-5">
        <Banner />
        <Brands />
        <Planes />
      </Section>
      <Section>
        <Asesores />
      </Section>
      <Section id="equipos" classSection="bg-white py-5">
        <Dispositivos />
      </Section>
      <Section>
        <TrabajaConNosotros />
      </Section>
      <FixedButton />
    </div>
  );
}
