import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { MyButton } from "../../../Components/Client";
import "./ConsultCredit.css";
import axios from 'axios'
import { TOKEN, BASE_API } from "../../../utils/constants.js";
import { toast, ToastContainer } from "react-toastify";
import Modal from 'react-bootstrap/Modal';

export function ConsultCredit() {
  const [clientCc, setClientCc] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [gottenElement, setGottenElement] = React.useState(null);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const notify = () => {
    toast.error('Cedula no encontrada', {
      position: "bottom-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  const onSubmit = async () => {
    try {
      const headers = {
        Authorization: `${TOKEN}`
      };
      const response = await axios.get(`${BASE_API}/dm/api/v1/devices.json?search_string=${clientCc}`, { headers });

      const foundElement = response.data?.devices.find(device => device.name === clientCc);
      if (foundElement) {
        setGottenElement(foundElement);
        setModalShow(true);
      } else {
        notify();
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="consult-credit">
      <div className="consult-credit__content py-4 px-5">
        <h1 className="h1custom">Consultar tu credito</h1>
        <Form onSubmit={onSubmit}>
          <Form.Input
            name="cc"
            placeholder="Ingresa tu cedula"
            type="number"
            onChange={(_, data) => setClientCc(data.value)}
          />
          <Button primary fluid disabled={isButtonDisabled}>
            Buscar
          </Button>
        </Form>
      </div>
      <MyButton nombre="Volver a HOME" />
      <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            device={gottenElement ? gottenElement : ""}
          />
    </div>
  );
}

function MyVerticallyCenteredModal(props) {
  const deviceData = props.device;  
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation
    >
      <Modal.Header className="background_color_app brd-btm" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {deviceData?.customer_name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="background_color_app">
        <h3 className="mb-2">CC: {deviceData?.name}</h3>
        <h4 className="mt-0 mb-2">Equipo: {deviceData?.make} {deviceData?.model}</h4>
        <h4 className="mt-0 mb-2">Estatus: {deviceData?.locked === false ? "Desbloqueado": "Bloqueado"}</h4>
      </Modal.Body>
      <Modal.Footer className="background_color_app brd-top">
        <p className="text_muted">Estatus Enrolamiento: {deviceData?.enrollment_status === "Completed"? "Completo" : "Error"} - Proxima fecha de corte: {deviceData?.next_lock_date}</p>
      </Modal.Footer>
    </Modal>
  );
}
