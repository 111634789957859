import telefonos from "../../../assets/img/telefonos.PNG";
import { MyButton } from "../MyButton";
import "./asesores.css";

export function Asesores() {
  return (
    <div
      className="d-flex align-items-center py-5 justify-content-center"
      style={{ backgroundColor: "#F4F9FC" }}
    >
      <div
        className="asesoresFlex d-flex justify-content-center align-items-center"
        style={{ maxWidth: "80rem" }}
      >
        <div
          style={{ width: "50%" }}
          className="my-4 firstDiv d-flex justify-content-center align-items-center"
        >
          <div
            style={{ maxWidth: "40rem" }}
            className="px-4 d-flex justify-content-center align-items-center"
          >
            <img
              style={{ width: "100%", borderRadius: "30px" }}
              src={telefonos}
            />
          </div>
        </div>
        <div
          style={{ width: "50%" }}
          className="my-4 secondDiv d-flex justify-content-center align-items-center"
        >
          <div
            className="mx-5 d-flex justify-content-center align-items-start flex-column"
            style={{}}
          >
            <h2 style={{ color: "#2F2C57" }} className=" fs-1 ">
              Asesores expertos en marcas aliadas
            </h2>
            <p className="text-start fs-5" style={{ color: "#2F2C57" }}>
              Nuestros <span className="fw-bold">asesores expertos</span> te
              guiarán en la elección perfecta para ti. Con conocimiento profundo
              de nuestros dispositivos y planes, están listos para responder tus
              preguntas y ofrecerte soluciones personalizadas.
            </p>
            <p className="text-start fs-5" style={{ color: "#2F2C57" }}>
              ¡Confía en nuestros expertos para tu experiencia movil ideal!
            </p>
            <MyButton nombre="Conoce más" />
          </div>
        </div>
      </div>
    </div>
  );
}
