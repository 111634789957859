import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { FormEditDevice } from '../../../Components/Seller';
export function EditarDispositivo(params) {
    const { id } = useParams();
    return(
        <Container className='py-4'>
        <FormEditDevice/>
        </Container>
    )
};
