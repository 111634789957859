import React, { useState } from "react"
import { TOKEN, BASE_API, BASE_API_BACK } from "../../../utils/constants.js";
import Cookies from "universal-cookie";
import axios, { Axios } from "axios";
import "./login.css"
import { Button, Form } from "semantic-ui-react";
import { MyButton } from "../../../Components/Client";
import { toast } from "react-toastify";
const cookies = new Cookies();

export function Login(params) {
    const [sellerEmail, setSellerEmail] = useState(null);
    const [sellerIdGruop, setSellerIdGruop] = useState(null);
    const [sellerPassword, setSellerPassword] = useState(null);

    const notify = () => {
        toast.error('Id, Correo o Contraseña incorrectos', {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    const notifyNoExist = () => {
        toast.warning('Porfavor Registrate', {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }

    const onSubmit = async () => {
      if (sellerEmail.includes("@")) {
        try {
          const headers = {
              Authorization: `${TOKEN}`
          };
          
          const responseNuovo = await axios.get(`${BASE_API}/dm/api/v1/device_groups/${sellerIdGruop}.json`, { headers });
          
          try {
              //const responseBack = await axios.get(`${BASE_API_BACK}/api/asesores/obtener/${sellerIdGruop}`);
              
              if (sellerEmail === responseNuovo.data.admins[0].email) {
                  if (/*responseBack.status !== 404*/true) {
                      if (/*sellerPassword === responseBack.data.contraseña*/true) {
                        
                          //cookies.set("id", responseBack.data.id, { path: "/" });
                          //cookies.set("CC", responseBack.data.CC, { path: "/" });
                          //cookies.set("phone", responseBack.data.telefono, { path: "/" });
                          cookies.set("idGroup", sellerIdGruop, { path: "/" });
                          cookies.set("email", sellerEmail, { path: "/" });
                          cookies.set("name", responseNuovo.data.admins[0].name, { path: "/" });
                          window.location.href = "./Aliados/Inicio";
                      } else {
                          notify();
                      }
                  } else {
                      notifyNoExist();
                  }
              } else {
                  notify();
              }
          } catch (responseBackError) {
            console.log(responseBackError)
              if(responseBackError.response.status == 404){
                notifyNoExist()
              }else{
                notify()
              }
          }
        } catch (error) {
            if (error.response && error.response.status === 422) {
                notify();
            } else {
                // Manejar otros tipos de errores de la solicitud responseNuovo aquí, si es necesario.
                console.error("Error en la solicitud responseNuovo:", error);
            }
        }
      }else{
        // try{
          
        //   const responseBackAdmin = await axios.get(`${BASE_API_BACK}/api/asesores/obtener-superusuario/${sellerEmail}`);
        // }catch (error){
        //   if (error.response && error.response.status === 404) {
        //     notify();
        // } else {
        //     // Manejar otros tipos de errores de la solicitud responseNuovo aquí, si es necesario.
        //     console.error("Error en la solicitud responseNuovo:", error);
        // }
        // }
      }
      
  };
    return(
        <div className="consult-credit">
      <div className="consult-credit__content py-4 px-5">
        <h1 className="h1custom mx-5">Iniciar Sesion</h1>
        <Form onSubmit={onSubmit}>
          <Form.Input
            name="id"
            placeholder="ID Nuovo"
            type="number"
            required={true}
            onChange={(_, data) => setSellerIdGruop(data.value)}
          />
          <Form.Input
            name="email"
            placeholder="Email"
            type="text"
            required={true}
            onChange={(_, data) => setSellerEmail(data.value)}
          />
          <Form.Input
            name="password"
            placeholder="contraseña"
            type="password"
            onChange={(_, data) => setSellerPassword(data.value)}
            hidden={true}
          />
          <Button primary fluid>
            Buscar
          </Button>
        </Form>
      </div>
      <MyButton nombre="Volver a HOME" />
    </div>
    )
};
