import video from "../../../assets/img/documentos/VerificacionDeEnrolamiento.mp4";
import { MyButton } from "../MyButton";
import "./acciones.css";

export function Acciones() {
  return (
    <div
      className="acciones d-flex align-items-center py-5 justify-content-center"
      style={{ backgroundColor: "#F4F9FC" }}
    >
      <div
        className="asesoresFlex d-flex justify-content-center align-items-center"
        style={{ maxWidth: "80rem" }}
      >
        <div
          style={{ width: "50%" }}
          className="my-4 firstDiv d-flex justify-content-center align-items-center"
        >
          <div
            style={{ maxWidth: "40rem" }}
            className="px-4 d-flex justify-content-center align-items-center"
          >
            <video
              className="responsive-video"
              src={video}
              title="Verificacion de enrolamiento Video"
              controls
              style={{ width: "100%", borderRadius: "30px" }}
            ></video>
          </div>
        </div>
        <div
          style={{ width: "50%" }}
          className="my-4 secondDiv d-flex justify-content-center align-items-center"
        >
          <div
            className="mx-5 d-flex justify-content-center align-items-start flex-column"
            style={{}}
          >
            <h2 style={{ color: "#2F2C57" }} className=" fs-1 ">
              Acciones en la plataforma
            </h2>
            <p className="text-start fs-5" style={{ color: "#2F2C57" }}>
              Aquí tienes una explicación detallada sobre las acciones de
              verificación que tendrás en la plataforma y tus funciones como
              miembro de nuestro distinguido grupo de asesores VIP.
            </p>
            <MyButton nombre="Conoce más" />
          </div>
        </div>
      </div>
    </div>
  );
}
