import { SellerLayout, BasicLayout } from "../Layouts";
import { Login, Inicio, EditarDispositivo } from "../pages/Seller";

const routesSeller = [
  {
    path: "/login",
    layout: BasicLayout,
    component: Login,
  },
  {
    path: "/Aliados/Inicio",
    layout: SellerLayout,
    component: Inicio,
  },
  {
    path: "/Aliados/RegistroDispositivo/:id",
    layout: SellerLayout,
    component: EditarDispositivo,
  },

];

export default routesSeller;
