import { CarouselTestimonios } from "../CarouselTestimonios";
import { MyButton } from "../MyButton";
import { useState, useEffect } from "react";
import phones2 from "../../../assets/img/banner/phones2.webp";

export function TrabajaConNosotros() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [smallScreen, setSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth < 1000) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  }, [screenWidth]);
  return (
    <div
      style={{
        backgroundImage: `url(${phones2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        Height: "90rem",
        padding: "50px",
      }}
    >
      <div
        style={{ maxWidth: "85rem" }}
        className={`${
          smallScreen ? "flex-column" : "flex-row"
        }  d-flex justify-content-center align-items-center mx-auto `}
      >
        <div
          style={{
            width: smallScreen ? "100%" : "50%",
          }}
          className=" d-flex justify-content-center align-items-center mx-5"
        >
          <div
            className="p-3 d-flex justify-content-center align-items-start flex-column"
            style={{
              backgroundColor: "#2F2C57",
              borderRadius: "30px",
            }}
          >
            <h3 className="fs-1 mx-5 col-md-10 col-sm-12 title-info-home mb-4">
              Catalogo General de Equipos Disponibles:
            </h3>
            <p
              style={{ maxWidth: "35rem" }}
              className="text-start mx-5 paragraph-info-home mb-4"
            >
              Tenemos a credito las mejores y mas variadas marcas de todos tus equipos favoritos para que puedas tener la opción de escoger el mas se ajusta a tu medida.
            </p>
            <div className="mx-5">
              <MyButton inverted color="purple" nombre="Conoce más" />
            </div>
          </div>
        </div>
        <div
          style={{ width: smallScreen ? "90%" : "50%" }}
          className="my-3 d-flex justify-content-center align-items-center"
        >
          <CarouselTestimonios />
        </div>
      </div>
    </div>
  );
}
