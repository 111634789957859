import "./NavBar.css";
import imgSrc from "../../../assets/img/logos/logo-dark.jpg";
import { Icon } from "semantic-ui-react";
import MenuSvg from "../svg/MenuSvg";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom/dist/umd/react-router-dom.development";

export function NavBar() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [openNavigation, setOpenNavigation] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const location = useLocation();
  const isHomeRoute = location.pathname === "/";

  const toggleNavigation = () => {
    if (openNavigation) {
      setOpenNavigation(false);
    } else {
      setOpenNavigation(true);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 550) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className="fixed-top">
      <div
        className=" d-flex flex-row align-items-center justify-content-between"
        style={{ backgroundColor: "#2F2C57" }}
      >
        <div
          style={{
            width: "45%",
            backgroundColor: !isHomeRoute
              ? "#2F2C57"
              : scrolled
              ? "#2F2C57"
              : "#F4F9FC",
          }}
          className="px-2  d-flex align-items-center justify-content-center  py-4"
        >
          <div
            className="flex flex-row justify-content-center align-items-center"
            style={{ width: "100%", maxWidth: "30rem" }}
          >
            <a href="/">
              <img
                src={imgSrc}
                alt="logo"
                style={{
                  border:
                    scrolled | !isHomeRoute ? "1px solid #F4F9FC" : "none",
                }}
                className=" img-nav rounded-circle"
              />
            </a>
            <a
              style={{ color: "#2F2C57" }}
              className={`${
                scrolled | !isHomeRoute ? "scrolledTitle" : ""
              } fw-bold fs-6 mx-2 nav-link mt-2`}
            >
              TECNOCREDITO SHOP SAS
            </a>
          </div>
        </div>
        {windowWidth >= 950 ? (
          <nav
            style={{ width: "55%" }}
            className="section d-flex justify-content-center align-items-center px-5 py-2   "
          >
            <div className="fs-5  d-flex justify-content-between  row text-center justify-content-center align-items-center">
              <div className="col-2 mx-3">
                <a style={{ color: "#F4F9FC" }} href="/" className="nav-link">
                  Inicio
                  <span className="underline"></span>
                </a>
              </div>
              <div className="col-3 mx-3">
                <a
                  style={{ color: "#F4F9FC" }}
                  href="documentos"
                  className="nav-link"
                >
                  Documentos
                  <span className="underline"></span>
                </a>
              </div>

              <div className="col-2 mx-3">
                <a style={{ color: "#F4F9FC" }} href="#" className="nav-link">
                  Nosotros
                  <span className="underline"></span>
                </a>
              </div>
              <div className="col-2 mx-3">
                <a style={{ color: "#F4F9FC" }} href="#" className="nav-link">
                  Trabaja
                  <span className="underline"></span>
                </a>
              </div>
            </div>
          </nav>
        ) : (
          <div>
            <button
              style={{ backgroundColor: "transparent", border: "none" }}
              className="d-flex justify-content-center align-items-center px-5 py-2"
              onClick={toggleNavigation}
            >
              <MenuSvg openNavigation={openNavigation} />
            </button>
          </div>
        )}
      </div>
      {openNavigation && (
        <nav
          style={{
            backgroundColor: "#2F2C57",
            width: "55%",
            right: "0",
            top: "0",
          }}
          className="fullscreen-nav"
        >
          <div
            style={{
              position: "fixed",
              top: "50%",
              right: "0",
              top: "15px",
              transform: "translateY(-50%)",
            }}
            className="my-4"
          >
            <button
              style={{ backgroundColor: "transparent", border: "none" }}
              className="d-flex justify-content-center align-items-center px-5 py-2"
              onClick={toggleNavigation}
            >
              <MenuSvg openNavigation={openNavigation} />
            </button>
          </div>
          <div className="fs-3 d-flex flex-column align-items-center justify-content-center h-100">
            <a href="/" className="nav-link my-5">
              Inicio
              <span className="underline"></span>
            </a>
            <a href="/documentos" className="nav-link my-5">
              Documentos
              <span className="underline"></span>
            </a>
            <a href="/nosotros" className="nav-link my-5">
              Nosotros
              <span className="underline"></span>
            </a>
            <a href="/trabaja" className="nav-link my-5">
              Trabaja
              <span className="underline"></span>
            </a>
            <a href="/login" className="nav-link my-5">
              Login
              <Icon name="unlock" className=" mx-2" />
              <span className="underline"></span>
            </a>
          </div>
        </nav>
      )}
    </div>
  );
}
