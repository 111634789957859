import React, { useState, useEffect } from 'react';
import { TOKEN, BASE_API } from "../../../utils/constants.js";
import Cookies from "universal-cookie";
import { Button } from "semantic-ui-react";
import axios from "axios";
import { TableDevices } from '../../../Components/Seller';
import Container from 'react-bootstrap/Container';
const cookies = new Cookies();
export function Inicio(params) {
    const [sellerName, setSellerName] = useState(null);
    const [sellerDevices, setSellerDevices] = useState(null);
    const [count, setCount] = useState(0);
    useEffect(() => {
        if (!cookies.get("id") && !cookies.get("email")) {
            window.location.href = "../login";
          }
        async function fetchData(){
            if (count < 2) {
                try {
                    const headers = {
                        Authorization: `${TOKEN}`
                    };
                    const response = await axios.get(`${BASE_API}/dm/api/v1/device_groups/${cookies.get("idGroup")}.json?devices_per_page=500`, { headers });
                    setSellerName(response.data.name)
                    setSellerDevices(response.data.devices)               
                    
                    } catch (error) {
                        console.error(error)
                    }
                setCount(count + 1);
              }
            
        }
        fetchData()
        
    });
    return(
        <>      
        <Container className='py-4'>
            <TableDevices devices={sellerDevices}/>
        </Container>
        </>
    )
};
