import video from "../../../assets/img/documentos/Enrolamiento.mp4";
import "./enrolamientoPasos.css";
import power from "../../../assets/img/icons/power.png";
import qr from "../../../assets/img/icons/qr.png";
import wifi from "../../../assets/img/icons/wifi.png";
import settings from "../../../assets/img/icons/ajuste.png";
import check from "../../../assets/img/icons/cheque.png";

export function EnrolamientoPasos() {
  return (
    <div className=" d-flex justify-content-center align-items-center flex-column">
      <div className="videoTop" style={{ width: "100%" }}>
        <video
          className="responsive-video"
          src={video}
          title="Verificacion de enrolamiento Video"
          controls
          style={{ width: "100%", borderRadius: "30px" }}
        ></video>
      </div>
      <div className="enrolamientoPasos py-5 d-flex justify-content-center align-items-center ">
        <h2 className="fs-1 mx-4">Enrolamiento en pasos</h2>
        <p style={{ maxWidth: "30rem" }} className="paragraph-info-home mx-5">
          En no más de 5 pasos se logra el enrolamiento del celular a la
          organizacion, no te olvides de revisar nuestro video en la parte
          superior.
        </p>
      </div>
      <div className="iconsSteps mt-5">
        <div className="mb-5 ">
          <div className="timeline">
            <div className="line"></div>
            <div className="event">
              <img className="icon" src={qr} />
            </div>
            <div className="event">
              <img className="icon" src={wifi} />
            </div>
            <div className="event">
              <img className="icon" src={check} />
            </div>
            <div className="event">
              <img className="icon" src={settings} />
            </div>
            <div className="event">
              <img className="icon" src={power} />
            </div>
          </div>
        </div>
        <div className="TEXTS mb-5 gap-2 d-flex flex-row justify-content-center align-items-start">
          <div className="text-item d-flex justify-content-center align-items-center flex-column">
            <h1 className="text-center">Prender</h1>
            <p className="text-center">Prender Celular y dar 6 toques</p>
          </div>
          <div className="text-item d-flex justify-content-center align-items-center flex-column">
            <h1 className="text-center">Escanear</h1>
            <p className="text-center">Una vez prendido, escanear.</p>
          </div>
          <div className="text-item d-flex justify-content-center align-items-center flex-column">
            <h1 className="text-center">Wifi</h1>
            <p className="text-center">
              Por necesidad del proceso, conectar a una red
            </p>
          </div>
          <div className="text-item d-flex justify-content-center align-items-center flex-column">
            <h1 className="text-center">Ajustar</h1>
            <p className="text-center">
              Paso a paso ajustando los datos necesarios
            </p>
          </div>
          <div className="text-item d-flex justify-content-center align-items-center flex-column">
            <h1 className="text-center">Listo</h1>
            <p className="text-center">Celular enrolado con la organización.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
