import { Button, Form, Segment } from 'semantic-ui-react'
import React, { useState } from "react"
import { toast, ToastContainer } from "react-toastify";
import { TOKEN, BASE_API } from "../../../utils/constants.js";
import axios from "axios";
import { formatInTimeZone } from 'date-fns-tz';
import { useParams } from 'react-router-dom';

const colombiaTimeZone = 'America/Bogota';

function GetLimitDate(currentDate) {
    const dateProcessed = currentDate.split('-')
    const year = parseInt(dateProcessed[0])
    const month = parseInt(dateProcessed[1])
    const day = parseInt(dateProcessed[2])
    let nextDayCut = 0
    let nextMonthCut = 0
    let nextYearCut = year
    var returnData = 0
    if (day > 5 && month == 12) {
        nextYearCut = year+1
    }
    if(day >= 5 && day <= 20){
        nextDayCut = 5
        nextMonthCut = month+1
    }else if (day > 20 ) {
        nextDayCut = 20
        nextMonthCut = month+1
    }else if (day < 5) {
        nextDayCut = 20
        nextMonthCut = month
    }
    returnData = nextDayCut+"/"+(nextMonthCut < 10 ? 0+""+nextMonthCut : nextMonthCut)+"/"+nextYearCut+"T11:00:00"
    return returnData
  }
export function FormEditDevice() {
    const [nameDevice, setNameDevice] = useState(null);
    const [firstNameCustomer, setFirstNameCustomer] = useState(null);
    const [lastNameCustomer, setLastNameCustomer] = useState(null);
    const currentDate = new Date(); // Fecha actual en UTC
    const dateNow = formatInTimeZone(currentDate, colombiaTimeZone, 'yyyy-MM-dd')
    const nextLockDate = (GetLimitDate(dateNow))
    const { id } = useParams();
    const dataValues = {
        device: {
          name: nameDevice,
          //first_lock_date:nextLockDate,
          user: {
            first_name: firstNameCustomer,
            last_name: lastNameCustomer,
            
          }
        }
    }
    const onSubmit = async () => {
        const config = {
            headers: {
                Authorization: `${TOKEN}`, // Tipo de contenido
              // Otros encabezados personalizados si es necesario
            },
          };
          axios.patch(`${BASE_API}/dm/api/v1/devices/${id}.json`, dataValues, config)
          .then(response => {
            toast.success('Informacion actualizada correctamente', {
                position: "bottom-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                });
          })
          .catch(error => {
            
            toast.error('Algo salio mal!!', {
                position: "bottom-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                });
            console.error('Error:', error);
          });
    };
    return(
        <Segment inverted>
            <Form inverted onSubmit={onSubmit}>
            <Form.Group widths='equal'>
                <Form.Input fluid required={true} label='CC' type='number' placeholder='CC' onChange={(_, data) => setNameDevice(data.value)}/>
                <Form.Input fluid required={true} label='Nombre' placeholder='Nombre(s)' onChange={(_, data) => setFirstNameCustomer(data.value.toUpperCase())}/>
                <Form.Input fluid required={true} label='Apellidos' placeholder='Apellidos' onChange={(_, data) => setLastNameCustomer(data.value.toUpperCase())}/>
            </Form.Group>
            
            <div className='col-12 d-flex justify-content-center'>
                <Button type='submit' color='green'>Registrar</Button>
            </div>
            </Form>
        </Segment>
    )
};
